// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { headerOptionsState } from '../../atoms/appstate';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// 아이콘
import btnHome from '../icons/B-Home.svg';
import btnBack from '../icons/B-Back.svg';
import btnBackWight from '../icons/W-Home.svg';
import btnMy from '../icons/B-My.svg';
import btnMyWight from '../icons/W-My.svg';
// import PalettePng from '../../assets/palette.png';
import Cookies from 'js-cookie';
import axios from 'axios';
// 리코일
import { useSetRecoilState } from 'recoil';
import { userNameState } from '../../atoms/userSate';
import { userInfoState } from '../../atoms/userInfoState';
import * as React from 'react';
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItem,
	Divider,
	Box,
	Drawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PaletteIcon from '@mui/icons-material/Palette';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import menuData from './menuData/menuData.json';
import PublicModal from './PublicModal';
import { useTranslation } from 'react-i18next';

export default function () {
	const location = useLocation();
	const headerOptions = useRecoilValue(headerOptionsState);
	const navigate = useNavigate();
	// 기본값 당신은 뉴비
	const [isNewbie, setIsNewbie] = useState(true);
	const setUserName = useSetRecoilState(userNameState);
	const [userInfo, setUserInfo] = useRecoilState(userInfoState);
	const [open, setOpen] = React.useState(false);
	const [isModal, setIsModal] = useState(false);
	const { t } = useTranslation();

	const handleOffModal = () => {
		setIsModal(false);
	};

	useEffect(() => {
		const handleLoginConfirm = () => {
			const token = Cookies.get('authToken'); // 쿠키에서 토큰 가져오기
			const formData = new FormData();
			if (token) {
				formData.append('request', JSON.stringify({ token })); // 'request' 키로 토큰을 JSON 형식으로 포함
				axios
					.post('https://api.huepick.net/member/status', formData)
					.then((response) => {
						const last_name = response.data.profile.last_name;
						setUserName(last_name); // Recoil 상태 업데이트
						//console.log('Login 상태는? :', response.data);
						// console.log(
						// 	'생일 null == 뉴비 :',
						// 	response.data.profile.birth,
						// );
						// 생일값이 초기값인 null 이면 뉴비상태로 정의
						if (response.data.profile.birth === null) {
							setIsNewbie(true);
						}

						if (response.data.profile.birth !== null) {
							setIsNewbie(false);
						}
					})
					.catch((error) => {
						console.error('Login not:토큰있는데 안유효', error);
					});
			} else {
				console.log('토큰없음');
			}
		};
		handleLoginConfirm();
	}, [location]);

	const goBackward = () => {
		if (location.pathname === '/pick') {
			navigate('/upload');
		} else if (location.pathname === '/statistics') {
			navigate('/pick');
		} else {
			// 그 외의 경우, 이전 페이지로 이동
			navigate(-1);
		}
	};

	const backward = useMemo(() => {
		if (headerOptions.showHeader && headerOptions.showBackward) {
			const buttonImage =
				location.pathname === '/pick' ? btnBackWight : btnBack;
			const altText = location.pathname === '/pick' ? 'Home' : 'Back';

			return (
				<img
					src={buttonImage}
					alt={altText}
					className="centered-icon"
					style={{ width: '24px', height: '24px' }}
					onClick={() => {
						goBackward();
					}}
				/>
			);
		}

		return <div style={{ color: 'black' }} className="col-span-1"></div>;
	}, [headerOptions, location.pathname]);

	const logout = () => {
		Cookies.remove('authToken');
		setUserName('');
		setUserInfo(null);
		setIsNewbie(true);
		setIsModal(false);
		navigate('/');
	};

	const DrawerList = (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={() => setOpen(false)}
		>
			<Divider />
			<List>
				{(!isNewbie
					? menuData.filter((menu) => menu.id != '3.1')
					: menuData.filter((menu) => menu.id == '3.1')
				).map((menu) => (
					<ListItem
						key={menu.id}
						onClick={() => {
							if (menu.id == '3.2') {
								setIsModal(true);
							} else {
								navigate(menu.link);
							}
						}}
						disablePadding
					>
						<ListItemButton>
							<ListItemIcon>
								{menu.id == '1' ? (
									<PaletteIcon />
								) : menu.id == '2' ? (
									<PersonIcon />
								) : menu.id == '3.1' ? (
									<LoginIcon />
								) : menu.id == '3.2' ? (
									<LogoutIcon />
								) : (
									''
								)}
							</ListItemIcon>
							<ListItemText
								primary={
									menu.id == '1'
										? t('main:statistics')
										: menu.id == '2'
										? t('main:mypage')
										: menu.id == '3.1'
										? t('main:login')
										: menu.id == '3.2'
										? t('main:logout')
										: ''
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	const headerTitle = useMemo(() => {
		if (headerOptions.template) {
			return <>{headerOptions.template()}</>;
		}

		return (
			<span
				className="header-title"
				onClick={() => {
					navigate('/upload');
				}}
			>
				HuePick
			</span>
		);
	}, [headerOptions]);

	if (headerOptions.showHeader) {
		return (
			<>
				<Drawer
					anchor={'right'}
					open={open}
					onClose={() => setOpen(false)}
				>
					{DrawerList}
				</Drawer>
				<header
					className="header grid grid-cols-6 justify-center items-center"
					style={{ padding: '0 5%' }}
				>
					{backward}
					<div className="col-span-4">{headerTitle}</div>
					<MenuIcon onClick={() => setOpen(true)} />
				</header>
				{isModal && (
					<PublicModal
						handleOffModal={handleOffModal}
						title={t('main:logout')}
						message={t('main:logout-question')}
						onConfirm={logout}
						onCancel={true}
					/>
				)}
			</>
		);
	}
	return null;
}
