import { useEffect, useState } from 'react';
import '../styles/CreateAccount.css';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
// aler 공용 모달
import PublicModal from '../components/modal/PublicModal';
import { useTranslation } from 'react-i18next';

const CreateAccount: React.FC = () => {
	const { t } = useTranslation();
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const navigate = useNavigate();
	const [isModal, setIsModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalMessage, setModalMessage] = useState('');
	const [onModalConfirm, setOnModalConfirm] = useState<() => void>(() => {});

	const handleOffModal = () => {
		setIsModal(false);
	};

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => <span style={{ color: '#000000' }}>Register</span>,
		});
	}, [setHeaderOptions]);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [emailCode, setEmailCode] = useState('');
	const [inputEmailCode, setInputEmailCode] = useState('');
	const [isEmailSent, setIsEmailSent] = useState(false);

	const nameRegex = /^[a-zA-Z가-힣]+$/;
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	const validateForm = () => {
		if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
			setModalMessage(t('main:check-name'));
			setIsModal(true);
			return false;
		}
		if (!emailRegex.test(email)) {
			setModalMessage(t('main:invalid-email'));
			setIsModal(true);
			return false;
		}
		if (password === '' || confirmPassword === '') {
			setModalMessage(t('main:enter-password'));
			setIsModal(true);
			return false;
		}
		if (password !== confirmPassword) {
			setModalMessage(t('main:check-password'));
			setIsModal(true);
			return false;
		}
		if (inputEmailCode === '') {
			setModalMessage(t('main:enter-email-code'));
			setIsModal(true);
			return false;
		}
		if (emailCode !== inputEmailCode) {
			setModalMessage(t('main:no-match-email-code'));
			setIsModal(true);
			return false;
		}
		return true;
	};

	const handleRegister = () => {
		if (validateForm()) {
			const formData = new FormData();
			const requestPayload = {
				first_name: firstName,
				last_name: lastName,
				user_id: email,
				user_pw: password,
			};
			formData.append('request', JSON.stringify(requestPayload));

			axios
				.post('https://api.huepick.net/member/register', formData)
				.then(() => {
					setModalMessage(t('main:add-information'));
					setIsModal(true);
					setOnModalConfirm(() => () => navigate('/signin'));
				})
				.catch((error) => {
					console.error('Registration error', error);
					setModalMessage(t('main:error'));
					setIsModal(true);
					return;
				});
		}
	};

	const handleEmailAuthentication = () => {
		if (!emailRegex.test(email) || email === '') {
			setModalMessage(t('main:invalid-email'));
			setIsModal(true);
			return;
		}

		const formData = new FormData();
		formData.append('request', JSON.stringify({ user_id: email }));
		axios
			.post('https://api.huepick.net/member/register/email', formData)
			.then((response) => {
				const code = response.data.auth.code.toString(); // 인증 코드를 문자열로 변환
				setEmailCode(code);
				setIsEmailSent(true);
			})
			.catch(() => {
				setModalMessage(t('main:error'));
				setIsModal(true);
				return;
			});
	};

	return (
		<div className="create-account-bg">
			<div className="flex flex-col mb-5">
				<span className="input_title-text">{t('main:firstname')}</span>
				<input
					type="text"
					className="input-form"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">{t('main:lastname')}</span>
				<input
					type="text"
					className="input-form"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">{t('main:email')}</span>
				<div className="flex justify-between">
					<input
						type="text"
						className="input-form-width"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<button
						className="btn-email-post"
						onClick={handleEmailAuthentication}
						disabled={isEmailSent}
					>
						{t('main:send')}
					</button>
				</div>
				{isEmailSent && (
					<span className="text-[12px] text-[#4430bd] mt-2">
						{t('main:send-code')}
					</span>
				)}
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">
					{t('main:email-verification-code')}
				</span>
				<div className="flex justify-between">
					<input
						type="text"
						className="input-form-width"
						value={inputEmailCode}
						onChange={(e) => setInputEmailCode(e.target.value)}
					/>
				</div>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">{t('main:password')}</span>
				<input
					type="password"
					className="input-form"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">
					{t('main:re-password')}
				</span>
				<input
					type="password"
					className="input-form"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
			</div>
			<button className="btn-register" onClick={handleRegister}>
				{t('main:register')}
			</button>
			{isModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title={modalTitle}
					message={modalMessage}
					onConfirm={onModalConfirm}
				/>
			)}
		</div>
	);
};

export default CreateAccount;
